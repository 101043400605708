// import React, {useState} from 'react';
//
// const HowToPlay = () => {
//
//     const [activeIndex, setActiveIndex] = useState(1)
//
//     const items = [
//         {
//             index: 1,
//             title: 'Title 1'
//         }, {
//             index: 2,
//             title: 'Title 2'
//         }, {
//             index: 3,
//             title: 'Title 3'
//         }, {
//             index: 4,
//             title: 'Title 4'
//         }
//     ]
//
//     const HowToPlayItem = ({item, index}) => {
//         return <li>
//
//         </li>
//     }
//
//     return (
//         <div
//             className={'how_to_play'}
//         >
//             <div className="how_to_play_overlay">
//                 <div className="how_to_play_wrapper">
//                     <div className="how_to_play_content">
//
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default HowToPlay;

import React, {useState} from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';

const HowToPlaySlider = ({setHowToVisible}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState('');
    const [animationKey, setAnimationKey] = useState(0);

    const slides = [
        {
            img: 'step_1',
            content: <p>Select a column to place your card.</p>
        },
        {
            img: 'step_2',
            content: <><p>Placing identical cards in the same column doubles or triples your score.</p> <p>Two matching
                cards give a x2 multiplier</p> <p>Three matching cards give a x3 multiplier.</p></>
        },
        {
            img: 'step_3',
            content: <p>Outsmart your opponent! Place a matching card in their column to remove their card!</p>
        },
        {
            img: 'step_4',
            content: <p>Achieve a higher score than your opponent before either board is completely filled, and claim
                victory!</p>
        },
    ];

    const nextSlide = () => {
        if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
            setDirection('right');
            setAnimationKey(prev => prev + 1);
        }
    };

    const prevSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
            setDirection('left');
            setAnimationKey(prev => prev + 1);
        }
    };

    return (
        <div className={'how_to'}>
            <div
            onClick={() => setHowToVisible(false)}
                className={"how_to_close"}
            >X</div>
            <h1>How To Play</h1>
            <div className="slider-container">
                <div
                    key={animationKey}
                    className={`slider-content fade-${direction}`}
                >
                    <img src={`/images/clash/${slides[currentSlide].img}.png`} alt={`how_to_olay`}/>
                    <p>{slides[currentSlide].content}</p>
                </div>
                <div className="slider-controls">
                    <div className="buttons_wrapper">
                        <button
                            onClick={prevSlide}
                            className={currentSlide === 0 ? 'disabled' : ''}
                            disabled={currentSlide === 0}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="45" viewBox="0 0 41 45"
                                 fill="none">
                                <path d="M-9.83506e-07 22.5L41 45L41 3.62669e-06L-9.83506e-07 22.5Z" fill="#4D9636"/>
                            </svg>
                        </button>
                        <button
                            onClick={nextSlide}
                            className={currentSlide === slides.length - 1 ? 'disabled' : ''}
                            disabled={currentSlide === slides.length - 1}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="45" viewBox="0 0 41 45"
                                 fill="none">
                                <path d="M41 22.5L-7.7492e-07 45L1.19209e-06 4.23533e-08L41 22.5Z" fill="#4D9636"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToPlaySlider;