import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import WithFrame from "../../HOC/WithFrame";
import ClashGame from "./game";
import WithLoading from "../../HOC/withLoading";
import withPte from "../../../Containers/withPte";
import {useLocation, useNavigate} from "react-router-dom";
import {APP_URLS} from "../../../urls";
import Counter from "../../../helpers/counter";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {makeConfetti} from "../../../helpers/confetti";
import mixCards from "../FlipCard/mixCards";
import HowToPlay from "./howToPlay";

const Clash = (props) => {

    const {playGameAction, getGameDataAction, gameInitData, tg, setGameDefault, isGameLoading, isPlayGame} = props

    const location = useLocation();
    const navigate = useNavigate();
    const [initDataUnsafe, initData] = useInitData();


    const [howToVisible, setHowToVisible] = useState(false);
    const [winPopup, setWinPopup] = useState(false);
    const [hideWinPopup, setHideWinPopup] = useState(false);
    const [responseData, setResponseData] = useState(null)
    const [isTimeToPlay, setIsTimeToPlay] = useState(false)
    const [nextPlayTime, setNextPlayTime] = useState(null)

    const playGame = (result, cb) => {
        playGameAction(initDataUnsafe.user.id, {
            // playGameAction(7086000566, {
            mini_game_id: location.hash.split('#')[1],
            reward: result
        }, (data) => {
            cb();
            setResponseData(data)
        })
    }

    useEffect(() => {
        // getGameDataAction(7086000566, location.hash.split('#')[1])
        initDataUnsafe?.user && location.hash && getGameDataAction(initDataUnsafe?.user?.id, location.hash.split('#')[1])

        tg?.BackButton?.show()
        tg?.BackButton?.onClick(() => navigate(APP_URLS.routes.p_t_e))

        return () => {
            setGameDefault()
        }
    }, []);

    useEffect(() => {
        if (gameInitData) {
            setNextPlayTime(gameInitData.next_play)
        }
    }, [gameInitData]);

    useEffect(() => {
        if (nextPlayTime) {
            let now = new Date().getTime()

            if (now >= nextPlayTime) {
                setIsTimeToPlay(true)
            } else {
                setIsTimeToPlay(false)
            }
        }
    }, [nextPlayTime])

    useEffect(() => {
        if (responseData) {

            if(responseData.is_winner) {
                tg.HapticFeedback.notificationOccurred('success')
                setTimeout(() => {
                    tg.HapticFeedback.notificationOccurred('success')
                }, 50)
                makeConfetti()
            } else {
                tg.HapticFeedback.notificationOccurred('error')
            }

            setWinPopup(true)

            setTimeout(() => {
                setNextPlayTime(responseData.next_play)
            }, 3000)
        }
    }, [responseData]);

    return (
        <WithFrame
            className={classNames({
                'clash_game': true
            })}
            header={
                <div className='penalty_shot_header'>
                    <span>MPEPPE Clash</span>
                    <span
                        onClick={() => setHowToVisible(true)}
                    >
                        <img style={{width: '20px'}} src={'/images/clash/how.svg'} alt={'how to play'}/>
                    </span>

                </div>
            }
        >
            {gameInitData && !isTimeToPlay ? <div className={
                classNames({
                    "loader_wrapper": true,
                })
            }>
                <div className={'timer_wrapper'}>
                    <Counter
                        endTimerCallback={() => setIsTimeToPlay(true)}
                        dueDate={nextPlayTime}
                    />
                </div>
            </div> : null}
            <WithLoading
                isLoading={isGameLoading || isPlayGame}
            >
                {winPopup ? <div className={classNames({
                    'level_up_popup': true
                })}>
                    {responseData.is_winner ? <><h2 style={{
                            fontSize: '38px',
                            lineHeight: 1,
                            marginBottom: '40px',
                            fontFamily: 'Saira-700, san-serif'
                        }}>Congratulations!</h2>
                            <p style={{margin: '0 0 60px'}}>You won <span
                                className='points'>{responseData.benefit_amount} MP</span> Come back in  an hour
                                to earn more.</p>
                            <button
                                onClick={() => {
                                    setHideWinPopup(true)
                                    setWinPopup(false)
                                }}
                                className={'invite_button_button no_bg'}
                            >Ok
                            </button>
                        </> :
                        <>
                            <h2 style={{
                                fontSize: '38px',
                                lineHeight: 1,
                                marginBottom: '40px',
                                fontFamily: 'Saira-700, san-serif'
                            }}>Oh no...</h2>
                            <p>Unfortunately, you didn't win. Don't be upset. Next time you'll definitely be lucky!</p>
                            <p style={{margin: '30px 0 60px'}}>See You next time!</p>
                            <button
                                onClick={() => {
                                    setHideWinPopup(true)
                                    setWinPopup(false)
                                }}
                                className={'invite_button_button no_bg'}
                            >Ok
                            </button>
                        </>}
                </div> : null}

                <ClashGame
                    tg={tg}
                    playGame={playGame}
                    gameInitData={gameInitData}
                    isTimeToPlay={isTimeToPlay}
                    winPopup={winPopup}
                    setWinPopup={setWinPopup}
                    hideWinPopup={hideWinPopup}
                    setHideWinPopup={setHideWinPopup}
                />
            </WithLoading>
            {howToVisible && <HowToPlay
                setHowToVisible={setHowToVisible}
            />}

        </WithFrame>
    );
};

export default withPte(Clash);